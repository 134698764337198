import React from 'react'
import teamCloseupJpg from '../images/home/team-closeup.jpg'
import teamCloseupWebp from '../images/home/team-closeup.webp'
import IndexLayout from '../layouts'

export default function Home() {
  return (
    <IndexLayout>
      <h1 className="sectionTitle" style={{ marginTop: '5rem', marginBottom: '5rem' }}>
        Flieg herein
      </h1>
      <div style={{ display: 'flex', flexDirection: 'row', margin: '1rem', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
        <div style={{ marginRight: '1rem' }}>
          <u>Yoga</u>
        </div>
        <div>
          dem Denken eine Pause gönnen
          <br />
          den Körper in Balance bringen
          <br />
          der Seele Lebensraum geben
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', margin: '1rem', justifyContent: 'center', alignItems: 'flex-start' }}>
        <div style={{ marginRight: '1rem' }}>
          <u>Beratung</u>
        </div>
        <div>
          Verletzungen auffangen
          <br />
          Glaubenssätze loslassen
          <br />
          der Seele Raum geben
          <br />
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', margin: '1rem', justifyContent: 'flex-start' }}>
        <div style={{ marginRight: '1rem' }}>
          <u>Schreiben</u>
        </div>
        <div>
          Ängste überwinden
          <br />
          die Kreativität entfesseln
          <br />
          der Seele Raum geben
          <br />
        </div>
      </div>
    </IndexLayout>
  )
}
